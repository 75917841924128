// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers

import { addPropertyControls, ControlType } from "framer"
import { motion } from "framer-motion"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Form(props) {
    const { tint } = props

    return (
        <form
            // here you have to paste your individual link from f.e. formspark
            action="Paste *SUBMIT* link here"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                fontFamily: "Inter, sans-serif",
                fontSize: "18px",
                color: "black",
                padding: "24px",
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.0)",
            }}
        >
            <input
                type="hidden"
                name="_redirect"
                // here you have to paste link, where do you want to lead your users to
                value="Paste *REDIRECTION* Link Here"
            />
            <label for="name" style={{ marginBottom: "8px" }}>
                Namn:
            </label>
            <input
                type="text"
                id="name"
                name="namn"
                placeholder="Namn"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
            />
            <label for="email" style={{ marginBottom: "8px" }}>
                E-post:
            </label>
            <input
                type="text"
                id="email"
                name="e-post"
                placeholder="E-post"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
            />
            <label for="phonenumber" style={{ marginBottom: "8px" }}>
                Telefonnummer:
            </label>
            <input
                type="text"
                id="number"
                name="telefonnummer"
                placeholder="Telefonnummer"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
            />
            <label for="personnummer" style={{ marginBottom: "8px" }}>
                Personnummer:
            </label>
            <input
                type="text"
                id="number"
                name="personnummer"
                placeholder="Personnummer"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
            />
            <label for="message" style={{ marginBottom: "8px" }}>
                Meddelande:
            </label>
            <textarea
                type="textarea"
                rows="4"
                id="message"
                name="meddelande"
                placeholder="Meddelande"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
            />
            <label style={{ marginBottom: "24px" }}>
                <input
                    type="checkbox"
                    required
                    style={{ marginRight: "8px" }}
                />
                Jag har gjort betalningen med Swish
            </label>
            <input
                type="submit"
                value="Skicka in"
                style={{
                    fontSize: "16px",
                    fontFamily: "Manrope, sans serif",
                    width: "100%",
                    padding: "16px",
                    backgroundColor: "#FFBF00",
                    color: "black",
                    fontWeight: "bold",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
            />
        </form>
    )
}

addPropertyControls(Form, {
    tint: {
        title: "Tint",
        type: ControlType.Color,
        defaultValue: "#09F",
    },
})

// Styles are written in object syntax
// https://react.dev/reference/react-dom/components/common#usage
const boxStyle = {
    margin: 25,
    width: 150,
    height: 150,
    borderRadius: 25,
}
